import React, { useEffect, useState } from "react"
import Layout from "../_components/Layout/Layout"
import { goto } from "../_utils/Misc"
import Database from "../_utils/Database"
import Qr from "../_utils/Qr"

const RewardLander = () => {
    const [count, setCount] = useState(3)
    const [start, setStart] = useState(false)
    const [game, setGame] = useState(false)
    const [mainImage, setMainImage] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [levelsNumber, setLevelsNumber] = useState(0)
    const [gamePayload, setGamePayload] = useState({
        title: '',
        levels: []
    })
    const [currentLevel, setcurrentLevel] = useState(0)
    const [choices, setChoices] = useState([])
    const [congratulations, setCongratulations] = useState(false)

    useEffect(() => {
        if (!!Qr.getGameParams()) {
            let gameInfo = Qr.getGameParams()

            setGamePayload(gameInfo)

            if (!!gameInfo && gameInfo.levels) {
                setLevelsNumber(gameInfo.levels.length - 1)
            }
        }

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }, [])

    const countDown = (modal) => {
        setStart(true)

        const timer = window.setInterval(() => {
            setCount(prevCount =>  {
                if(prevCount > 1) {
                    return prevCount - 1
                } else {
                    if(modal) {
                        if (Database.getUserToken()) {
                            goto("/qr-rewards-fetch/")
                        } else {
                            goto("/registration/")
                        }
                    } else {
                        setGame(true)
                    }

                    return window.clearInterval(timer)
                }
            })
        }, 1000)
    }

    const startGame = () => {
        countDown()
    }

    const goNext = (level, food) => {
        let choicesArray = (!!choices.length && choices) || []

        choicesArray.push(food.value)
        setLoaded(false)
        setChoices(choicesArray)

        if(level <= levelsNumber) {
            setcurrentLevel(level)
        } else {
            Qr.storeChoices(choicesArray)
            setCongratulations(true)
            setCount(3)
            countDown(true)
        }

        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }

    useEffect(() => {
        if (!Qr.getGameParams()) {
            goto("/")
        }
    }, [])

    const hideGame = () => {
        if (Database.getUserToken()) {
            goto("/qr-rewards-fetch/")
        } else {
            goto("/reward-lander/")
        }
    }

    return (
        <div className="taste-game">
            <Layout title="Taste Game">
                {!start && (
                    <div className="start-content">
                        <div>
                            <div className="main-title">
                                {gamePayload.title}
                            </div>

                            <div className="main-description game">
                                {gamePayload.description}
                            </div>

                            <div className="game-image main">
                                { !mainImage && (
                                    <span className="loading">
                                        Loading...
                                    </span>
                                )}

                                <img
                                    style={mainImage ? {} : { display: 'none' }}
                                    onLoad={() => setMainImage(true)}
                                    src={gamePayload.img_src}
                                    alt=""
                                />
                            </div>
                        </div>

                        <div className="game-buttons">
                            <button
                                onClick={startGame}
                                className="btn btn-yellow">
                                Start
                            </button>

                            { gamePayload.skip_option && (
                                <button
                                    onClick={hideGame}
                                    className="btn btn-default">
                                    Skip
                                </button>
                            )}
                        </div>
                    </div>
                )}

                { start && !congratulations && (
                    <div className="count-content">
                        {count}
                    </div>
                )}

                { game && !congratulations && gamePayload.levels[currentLevel] && (
                    <div>
                        <div className="main-title game">
                            {gamePayload.levels[currentLevel].title}
                        </div>

                        { gamePayload.levels[currentLevel].choices && (
                            <div className="game-level">
                                { gamePayload.levels[currentLevel].choices.map((food) => (
                                    <div className="game-image"
                                         onClick={() => goNext(currentLevel + 1, food)}>
                                        <span className="food-name">
                                            {food.name}
                                        </span>

                                        { !loaded && (
                                            <span className="loading">
                                                Loading...
                                            </span>
                                        )}

                                        <img
                                            style={loaded ? {} : { display: 'none' }}
                                            onLoad={() => setLoaded(true)}
                                            src={food.img_src}
                                            alt=""
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                )}

                { congratulations && (
                    <div className="won-content">
                        <div className="main-title">
                            { gamePayload.completion_message || 'Congratulations you have won!' }
                        </div>

                        <div className="main-content">
                            You will be redirected in {count} seconds
                        </div>
                    </div>
                )}
            </Layout>
        </div>
    )
}

export default RewardLander
